(function (Ruiterplaatmakelaardij) {

    Ruiterplaatmakelaardij.Elements = {

        slugify: function (element) {
            var target = jQuery(element.data('slugify-target'));

            target.data('fiets-zaken-changed', false);

            target.on('change keyup', function () {
                target.data('fiets-zaken-changed', target.val() != '');
            });

            element.on('change keyup', function () {
                if (target.data('fiets-zaken-changed') === false) {
                    target.val(Ruiterplaatmakelaardij.Util.slugify(element.val())).trigger('input');
                }
            });
        },

        clearInput: function () {
            jQuery(jQuery(this).data('clear-input')).val('');
        },

        formNewsletterList: function (form) {
            form.addClass('prepost-loading');

            form.find('.help-block.error-block').remove();
            form.find('.form-group.has-error').removeClass('has-error');

            jQuery.ajax({
                type:     'POST',
                dataType: "json",
                url:      form.attr('action'),
                data:     form.serialize(),
                success:  function (data) {
                    form.closest('.modal').modal('hide');

                    swal('', data, "success");
                },
                error:    function (data) {
                    if (data.status === 422) {
                        jQuery.each(data.responseJSON, function (field, msg) {
                            form.find('[name="' + field + '"]').closest('.form-group').addClass('has-error').append('<span class="help-block error-block">' + msg[0] + '</span>');
                        });
                    }
                }
            }).always(function () {
                form.removeClass('prepost-loading');
            });
        },

        init: function () {

            jQuery('[data-clear-input]').click(Ruiterplaatmakelaardij.Elements.clearInput);

            jQuery('input[data-slugify="true"][data-slugify-target]').each(function () {
                Ruiterplaatmakelaardij.Elements.slugify(jQuery(this));
            });
        }
    };

    Ruiterplaatmakelaardij.registerModule(jQuery.proxy(Ruiterplaatmakelaardij.Elements.init, Ruiterplaatmakelaardij.Elements));

})(window.Ruiterplaatmakelaardij);


