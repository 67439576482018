(function (Ruiterplaatmakelaardij) {

    Ruiterplaatmakelaardij.Selectize = {

        selectizes: {

            default: {
                plugins:      [],
                placeholder:  window.i18n.selectize.msg_type_to_search,
                sortField:    'text',
                params:       {
                    //
                },
                render:       {
                    item:          function (data, escape) {
                        return '<div class="item" ' + (data.caption ? 'data-title="' + escape(data.caption) + '" data-toggle="tooltip" data-container="body" data-context="selectize"' : '') + ' ' + (data.name ? 'data-name="' + escape(data.name) + '"' : '') + '>' + escape(data.text) + '</div>';
                    },
                    option:        function (item, escape) {
                        var element = '<div><span class="title">' + escape(item.text) + '</span> ' + (item.caption ? '<span class="description text-muted">' + escape(item.caption) + '</span>' : '');

                        // if (item.type) {
                        //     element += '<span class="description">' + escape(item.type) + '</span>';
                        // }

                        return element + '</div>';
                    },
                    option_create: function (data) {
                        return '<div data-selectable="" class="create">' + window.i18n.selectize.msg_create + ' <strong>' + data.input + '</strong>…</div>';
                    }
                },
                create:       function (input) {
                    if (!input.length) {
                        return;
                    }

                    return {
                        value: input,
                        text:  input
                    };
                },
                onItemAdd:    function (value, $item) {
                    // Reinitialize tooltips because they can be present
                    jQuery('[data-toggle*="tooltip"][data-context="selectize"]').tooltip();
                },
                onItemRemove: function (value) {
                    // Destroy the tooltips because otherwise they just keep on hanging
                    jQuery('body > .tooltip').remove();
                }
            },

            styled: {
                plugins:      [],
                placeholder:  window.i18n.selectize.msg_type_to_search,
                sortField:    false,
                create:       false,
                params:       {
                    //
                },
                render:       {
                    item:          function (data, escape) {
                        return '<div class="item" ' + (data.caption ? 'data-title="' + escape(data.type) + '" data-toggle="tooltip" data-container="body" data-context="selectize"' : '') + '>' + escape(data.text) + '</div>';
                    },
                    option:        function (item, escape) {
                        return '<div><span class="title">' + escape(item.text) + '</span> ' + (item.type ? '<span class="description text-muted">' + escape(item.type) + '</span>' : '') + '</div>';
                    },
                    option_create: function (data) {
                        return '<div data-selectable="" class="create">' + window.i18n.selectize.msg_create + ' <strong>' + data.input + '</strong>…</div>';
                    }
                },
                onItemAdd:    function (value, $item) {
                    // Reinitialize tooltips because they can be present
                    jQuery('[data-toggle*="tooltip"][data-context="selectize"]').tooltip();
                },
                onItemRemove: function (value) {
                    // Destroy the tooltips because otherwise they just keep on hanging
                    jQuery('body > .tooltip').remove();
                }
            },

            city: {
                plugins:      ['remove_button', 'restore_on_backspace'],
                persist:      false,
                hideSelected: true,
                openOnFocus:  true,
                create:       false,
                delimiter:    ',',
                maxItems:     1,
                sortField:    'value',
                reload:       'focus',

                load: function (query, callback) {
                    jQuery.get(Ruiterplaatmakelaardij.Util.getAPIUrl('/autocomplete/cities'), {
                        query: query
                    }, function (data) {
                        callback(data);
                    }).fail(function () {
                        callback();
                    });
                }
            },

            province: {
                plugins:      ['remove_button', 'restore_on_backspace'],
                persist:      false,
                hideSelected: true,
                openOnFocus:  true,
                create:       false,
                delimiter:    ',',
                maxItems:     1,
                sortField:    'value',
                reload:       'focus',

                load: function (query, callback) {
                    jQuery.get(Ruiterplaatmakelaardij.Util.getAPIUrl('/autocomplete/provinces'), {
                        query: query
                    }, function (data) {
                        callback(data);
                    }).fail(function () {
                        callback();
                    });
                }
            },

            country: {
                plugins:      ['remove_button', 'restore_on_backspace'],
                persist:      false,
                hideSelected: true,
                openOnFocus:  true,
                create:       false,
                delimiter:    ',',
                maxItems:     1,
                sortField:    'value',
                preload:      'focus',

                load: function (query, callback) {
                    jQuery.get(Ruiterplaatmakelaardij.Util.getAPIUrl('/autocomplete/countries'), {
                        query: query
                    }, function (data) {
                        callback(data);
                    }).fail(function () {
                        callback();
                    });
                }
            },

            companiesUserForm: {
                plugins:      ['remove_button', 'restore_on_backspace'],
                persist:      false,
                hideSelected: true,
                openOnFocus:  false,
                create:       false,
                delimiter:    ',',
                sortField:    'value',

                load: function (query, callback) {
                    if (!query.length) {
                        return callback();
                    }

                    jQuery.get(Ruiterplaatmakelaardij.Util.getAPIUrl('/autocomplete/companiesuserform'), {
                        query: query
                    }, function (data) {
                        callback(data);
                    }).fail(function () {
                        callback();
                    });
                }
            },

            placeParent: {
                plugins:      ['remove_button', 'restore_on_backspace'],
                persist:      false,
                hideSelected: true,
                create:       false,
                openOnFocus:  false,
                delimiter:    ',',
                maxItems:     1,
                sortField:    'value',

                load: function (query, callback) {
                    if (!query.length) {
                        return callback();
                    }

                    jQuery.get(Ruiterplaatmakelaardij.Util.getAPIUrl('/autocomplete/placesParent'), {
                        query: query
                    }, function (data) {
                        callback(data);
                    }).fail(function () {
                        callback();
                    });
                }
            },

            menuitem: {
                plugins:      ['remove_button', 'restore_on_backspace'],
                persist:      false,
                hideSelected: true,
                openOnFocus:  false,
                delimiter:    ',',
                mode:         'multi',
                maxItems:     1,
                sortField:    'value',

                load: function (query, callback) {
                    if (!query.length) {
                        return callback();
                    }

                    jQuery.get(Ruiterplaatmakelaardij.Util.getAPIUrl('/autocomplete/menuitem'), {
                        query: query
                    }, function (data) {
                        callback(data);
                    }).fail(function () {
                        callback();
                    });
                }
            },

            companySearchPlace: {
                plugins:      ['remove_button', 'restore_on_backspace'],
                persist:      true,
                hideSelected: true,
                openOnFocus:  false,
                create:       false,
                delimiter:    ',',
                mode:         'multi',
                maxItems:     1,
                sortField:    'value',

                render: {
                    item:          function (data, escape) {
                        return '<div class="item" ' + (data.caption ? 'data-title="' + escape(data.caption) + '" data-toggle="tooltip" data-container="body" data-context="selectize"' : '') + ' ' + (data.name ? 'data-name="' + escape(data.name) + '"' : '') + '>' + escape(data.text) + '</div>';
                    },
                    option:        function (item, escape) {
                        return '<div><span class="title">' + escape(item.text) + '</span> ' + (item.caption ? '<span class="description text-muted">' + escape(item.caption) + '</span>' : '') + '</div>';
                    },
                    option_create: function (data) {
                        return '<div data-selectable="" class="create">' + window.i18n.selectize.msg_search + ' <strong>' + data.input + '</strong>…</div>';
                    }
                },

                load: function (query, callback) {
                    if (!query.length) {
                        return callback();
                    }

                    jQuery.get(Ruiterplaatmakelaardij.Util.getAPIUrl('/autocomplete/companySearchPlace'), {
                        query: query
                    }, function (data) {
                        callback(data);
                    }).fail(function () {
                        callback();
                    });
                }
            },
        },

        init: function () {
            for (var selectize in this.selectizes) {
                if (this.selectizes.hasOwnProperty(selectize)) {
                    var value    = this.selectizes[selectize],
                        selector = '.selectize-' + selectize;

                    if (selectize === 'default') {
                        selector = '.selectize';
                    }

                    jQuery(selector).each(function (i, e) {
                        var elem    = jQuery(e),
                            opts    = {},
                            params  = elem.data('selectize-params'),
                            store   = jQuery('[data-selectize-store="' + elem.attr('name') + '"]'),
                            options = jQuery.extend(true, opts, Ruiterplaatmakelaardij.Selectize.selectizes.default, value);

                        if (params) {
                            opts.params = params;
                        }

                        if (store.length > 0) {
                            opts.items   = store.data('items');
                            opts.options = store.data('options');
                        }

                        opts.onChange = function () {
                            elem.trigger('input');
                        };

                        elem.selectize(opts);
                    });
                }
            }
        }
    };

    Ruiterplaatmakelaardij.registerModule(jQuery.proxy(Ruiterplaatmakelaardij.Selectize.init, Ruiterplaatmakelaardij.Selectize));

})(window.Ruiterplaatmakelaardij);


