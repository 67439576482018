(function () {

    // Init Ruiterplaatmakelaardij and all modules
    angular.module('ruiterplaatmakelaardij', [

        // Modules
        'mediapicker'

    ]);

})();
