(function (Ruiterplaatmakelaardij) {

    Ruiterplaatmakelaardij.DataTable = {

        datatables: {

            default: {
                options: {
                    responsive: true,

                    pageLength:   10,
                    searching:    true,
                    lengthChange: false,
                    stateSave:    true,
                    order:        [[0, 'desc']],

                    oLanguage: window.i18n.datatable,

                    processing: true,
                    serverSide: true,
                    ajax:       {
                        url:  Ruiterplaatmakelaardij.Util.getAPIUrl('/datatable'),
                        data: {
                            type: 'default'
                        }
                    },
                    columns:    [{}]
                }
            },

            menus: {
                options: {
                    ajax: {
                        data: {
                            type: 'menus'
                        }
                    },

                    columns: [{
                        data:  "title",
                        title: window.i18n.datatable.menus.columns.title.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            pages: {
                options: {
                    ajax: {
                        data: {
                            type: 'pages'
                        }
                    },

                    columns: [{
                        data:  "title",
                        title: window.i18n.datatable.pages.columns.title.title
                    }, {
                        data:  "type_name",
                        title: window.i18n.datatable.pages.columns.type_name.title
                    }, {
                        data:  "status_name",
                        title: window.i18n.datatable.pages.columns.status.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            },

            users: {
                options: {
                    ajax: {
                        data: {
                            type: 'users'
                        }
                    },

                    columns: [{
                        data:  "firstname",
                        title: window.i18n.datatable.users.columns.firstname.title
                    }, {
                        data:  "lastname",
                        title: window.i18n.datatable.users.columns.lastname.title
                    }, {
                        data:  "email",
                        title: window.i18n.datatable.users.columns.email.title
                    }, {
                        data:  "role_name",
                        title: window.i18n.datatable.users.columns.role.title
                    }, {
                        data:       "action_btn",
                        orderable:  false,
                        searchable: false,
                        width:      118,
                        title:      window.i18n.datatable.columns.action_btn.title
                    }]
                }
            }

        },

        init: function () {
            for (var datatable in this.datatables) {
                if (this.datatables.hasOwnProperty(datatable)) {
                    var value    = this.datatables[datatable],
                        selector = '.datatable-' + datatable;

                    if (datatable === 'default') {
                        selector = '.datatable';
                    }

                    jQuery(selector).each(function (i, e) {
                        var elem     = jQuery(e),
                            opts     = {},
                            settings = elem.data('datatable-settings'),
                            data     = elem.data('datatable-data'),
                            options  = jQuery.extend(true, opts, Ruiterplaatmakelaardij.DataTable.datatables.default.options, value.options);

                        if (settings) {
                            jQuery.extend(opts, settings);
                        }

                        if (data) {
                            jQuery.extend(opts.ajax.data, data);
                        }

                        var table = elem.DataTable(opts);
                        table.on('draw', function () {
                            elem.find('[data-confirm]').click(Ruiterplaatmakelaardij.Util.confirmClick);
                        })
                    });
                }
            }
        }
    };

    Ruiterplaatmakelaardij.registerModule(jQuery.proxy(Ruiterplaatmakelaardij.DataTable.init, Ruiterplaatmakelaardij.DataTable));

})(window.Ruiterplaatmakelaardij);
