(function (Ruiterplaatmakelaardij) {

    Ruiterplaatmakelaardij.Search = {

        refill: function (elem, fields) {
            var elements = jQuery('[name="country"],[name="province"],[name="region"],[name="city"]'),
                serialize = fields ? fields : elements.serialize();

            if (elem != false && !fields) {
                if (elem.attr('name') === 'country') {
                    serialize = jQuery('[name="country"]').serialize();
                } else if (elem.attr('name') === 'province') {
                    serialize = jQuery('[name="country"],[name="province"]').serialize();
                } else if (elem.attr('name') === 'region') {
                    serialize = jQuery('[name="country"],[name="province"],[name="region"]').serialize();
                }
            }

            elements.prop('disabled', true);
            jQuery.get(Ruiterplaatmakelaardij.Util.getAPIUrl('/autocomplete/select'), serialize, function (data) {
                Ruiterplaatmakelaardij.Search.fill(jQuery('[name="province"]'), data.provinces, (fields ? fields.province : false));
                Ruiterplaatmakelaardij.Search.fill(jQuery('[name="city"]'), data.cities, (fields ? fields.city : false));
                Ruiterplaatmakelaardij.Search.fill(jQuery('[name="region"]'), data.regions, (fields ? fields.region : false));
            }).always(function () {
                elements.prop('disabled', false);
            });
        },

        fill: function (elem, data, initVal) {
            var selectedVal = initVal ? initVal : elem.val();
            elem.html('');

            elem.append('<option value="" selected>' + jQuery('[for="' + elem.attr('id') + '"]').text() + '</option>');
            jQuery.each(data, function (val, name) {
                elem.append('<option value="' + val + '" ' + (selectedVal === val ? 'selected' : '') + '>' + name + '</option>');
            });
        },

        init: function () {
            if (jQuery('.searchpage').length) {
                jQuery('[name="country"],[name="province"],[name="region"]').change(function () {
                    var elem = jQuery(this);

                    if (elem.attr('name') === 'country') {
                        jQuery('[name="country"]:not(#' + elem.attr('id') + ')').val(elem.val());
                    }

                    Ruiterplaatmakelaardij.Search.refill(jQuery(this));
                });

                jQuery('[name="theme"]').change(function () {
                    var elem = jQuery(this);

                    jQuery('[name="theme"]:not(#' + elem.attr('id') + ')').val(elem.val());
                });

                Ruiterplaatmakelaardij.Search.refill(false, searchFields);
            }
        }
    };

    Ruiterplaatmakelaardij.registerModule(jQuery.proxy(Ruiterplaatmakelaardij.Search.init, Ruiterplaatmakelaardij.Search));

})(window.Ruiterplaatmakelaardij);


